import {dragAndDropUpload} from "../shared/drag_and_drop_upload";

$(()=> {

    const $targetUrl = app.DOM.content.find('#input-copy-tacho-url');
    const $targetToken = app.DOM.content.find('#input-copy-tacho-token');

    app.DOM.content.find('#btn-copy-tacho-token,#btn-copy-tacho-url').on('click', e => {

        const $el = $(e.currentTarget);

        // do nothing already clicked until timeout expires
        if( $el.hasClass('bg-green') ) {
            return;
        }

        if( $el.attr('id') === 'btn-copy-tacho-token' ) {
            $targetToken.select();
        } else {
            $targetUrl.select();
        }

        document.execCommand('copy');

        // change button state
        $el.addClass('bg-green').text('Copied!');

        setTimeout(()=>{
           $el.removeClass('bg-green').addClass().text('Copy');
        }, 2000);
    });

    app.DOM.content.find('p.submit-container > button.button-primary').on('click', e => {
        const $button = $(e.target)
        $button.prop('disabled', true)
        $button.parents('#form').submit()
    })

    dragAndDropUpload();
});